import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { toast } from 'react-hot-toast';
import CSCApi from '../../../services/CSCApi';
import api from '../../../config/api';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'


const emptyState = {
  name: '',
  last_name: '',
  address: '',
  email: '',
  phone: '',
  dni: '',
  guarantor_id: '',
  rfid: '',
  guarantor_contract: false, 
  consumption_contract: false, 
  scanned_dni: false, 
  payed_inscription: false, 
  monthly_consumption: 0,
  avatar: null,
  member_id: 0,
  member_number: '',
}




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function EditMemberModal({open, close, reload, members, member}) {
  const [memberInfo, setMemberInfo] = useState(emptyState)
  const [filteredMembers, setFilteredMembers] = useState(members);
  const [loading, setLoading] = useState(false);
  const [suggestedNumber, setSuggestedNumber] = useState(null);
  
  const handleCreateMember = async () => {
    if(loading) return;
    setLoading(true);
    try{
      toast.loading('Creando miembro...', {id: 'editMember'});
      const {message} = await CSCApi.editMember(memberInfo);
      toast.success(message, {id: 'editMember'});
      setMemberInfo(emptyState);
      close();
      reload();
    } catch(e){
      toast.error(e, {id: 'editMember'});
    }
    setLoading(false);
  }

  const handleOpenFileSelector = () => {
    const fileInput = document.getElementById('fileInput');
    fileInput.click();
  }
  
  const handleFileRead = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    const extensions = ['jpg', 'jpeg', 'png'];
    const extension = file.name.split('.').pop();
    const fileInput = document.getElementById('fileInput');
    if(!extensions.includes(extension)) {
      fileInput.value = '';
      return toast.error(`Solo se permiten archivos de tipo ${extensions.join(', ')}`);
    }
    reader.onload = (e) => {
      
      fileInput.value = '';
      setMemberInfo({...memberInfo, avatar: e.target.result});
    }
    reader.readAsDataURL(file);
  }

  useEffect(() => {
    if(member){
      setMemberInfo({
        name: member.name,
        last_name: member.last_name,
        address: member.address,
        email: member.email,
        phone: member.phone,
        dni: member.dni,
        guarantor_id: members.find(m => m.id === member.guarantor_id),
        rfid: member.rfid,
        guarantor_contract: member.guarantor_contract, 
        consumption_contract: member.consumption_contract,
        scanned_dni: member.scanned_dni,
        payed_inscription: member.payed_inscription,
        monthly_consumption: member.monthly_consumption,
        member_id: member.id,
        avatar: false,
        member_number: member.member_number || '',
      });
      setFilteredMembers(members);
      handleLoadSuggestedNumber();
    }
  }, [member])

  const handleLoadSuggestedNumber = async () => {
    try{
      const {message, suggestedNumber} = await CSCApi.suggestMemberNumber();
      setSuggestedNumber(suggestedNumber);
    } catch(e){
    }
  }

  const handleSelectAvalator = (e) => {
    const value = e.target.value;
    const filtered = members.filter(member => {
      const name = member.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const lastname = member.last_name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const nameAndLastname = `${name} ${lastname}`;
      const email = member.email.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const searchValue = value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return (
        name.includes(searchValue) ||
        email.includes(searchValue) ||
        lastname.includes(searchValue) ||
        nameAndLastname.includes(searchValue) ||
        (member.member_number === Number(value)) ||
        member.rfid === value
      );
    });
    setFilteredMembers(filtered);
  }

  
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={() => close()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex w-full sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-base pb-2 font-semibold leading-6 text-gray-900">
                      Editar socio
                    </Dialog.Title>
                    <div className="flex items-center justify-center p-4">
                            <span className="h-24 w-24 overflow-hidden rounded-full bg-gray-100 border border-green-600">
                              {memberInfo.avatar ? (<img src={memberInfo.avatar} className="h-full w-full rounded-full"
                              alt="Member Avatar" />) : (member.avatar ? (<img src={`${api.API_URL}/avatar/${member.avatar}`} className="h-full w-full rounded-full"
                              alt="Member Avatar" />) : (<svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                              </svg>))}
                            </span>
                            <input onChange={handleFileRead} id="fileInput" name="file-upload" type="file" className="sr-only hidden" />
                            <button onClick={handleOpenFileSelector}
                              type="button"
                              className="ml-5 rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                              Change
                            </button>
                          </div>
                    <div className='grid md:grid-cols-2 gap-x-6 gap-y-4'>
                      
                      <input value={memberInfo.name} onChange={(e) => setMemberInfo({...memberInfo, name: e.target.value})}
                        type="text" className="mt-2 p-2 w-full block shadow-sm sm:text-sm border border-gray-300 rounded-md" placeholder="Nombre" />
                      
                      <input value={memberInfo.last_name} onChange={(e) => setMemberInfo({...memberInfo, last_name: e.target.value})}
                        type="text" className="mt-2 p-2 w-full block shadow-sm sm:text-sm border border-gray-300 rounded-md" placeholder="Apellidos" />

                      <input value={memberInfo.address} onChange={(e) => setMemberInfo({...memberInfo, address: e.target.value})}
                        type="text" className="mt-2 p-2 w-full block shadow-sm sm:text-sm border border-gray-300 rounded-md" placeholder="Domicilio" />

                      <input value={memberInfo.dni} onChange={(e) => setMemberInfo({...memberInfo, dni: e.target.value})}
                        type="text" className="mt-2 p-2 w-full block shadow-sm sm:text-sm border border-gray-300 rounded-md" placeholder="DNI" />

                      <input value={memberInfo.phone} onChange={(e) => setMemberInfo({...memberInfo, phone: e.target.value})}
                        type="text" className="mt-2 p-2 w-full block shadow-sm sm:text-sm border border-gray-300 rounded-md" placeholder="Telefono" />

                      <input value={memberInfo.email} onChange={(e) => setMemberInfo({...memberInfo, email: e.target.value})}
                        type="text" className="mt-2 p-2 w-full block shadow-sm sm:text-sm border border-gray-300 rounded-md" placeholder="Email" />

                      <input value={memberInfo.rfid} onChange={(e) => setMemberInfo({...memberInfo, rfid: e.target.value})}
                        type="text" className="mt-2 p-2 w-full block shadow-sm sm:text-sm border border-gray-300 rounded-md" placeholder="Tarjeta Socio" />

                      <input value={memberInfo.monthly_consumption} onChange={(e) => setMemberInfo({...memberInfo, monthly_consumption: e.target.value})}
                        type="text" className="mt-2 p-2 w-full block shadow-sm sm:text-sm border border-gray-300 rounded-md" placeholder="Consumo Mensual" />

                      <div>
                          {suggestedNumber && <span className="text-xs text-gray-500"> Sugerido: {suggestedNumber}</span>}
                        <input value={memberInfo.member_number} onChange={(e) => setMemberInfo({...memberInfo, member_number: e.target.value})}
                          type="text" className="mt-2 p-2 w-full block shadow-sm sm:text-sm border border-gray-300 rounded-md" placeholder="Numero Socio" />
                      </div>
                      <Combobox as="div" value={memberInfo.guarantor_id} onChange={(value) => setMemberInfo({...memberInfo, guarantor_id: value})}
                      className="col-span-2">
                        <div className="relative mt-2">
                          <Combobox.Input
                            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                            onChange={handleSelectAvalator}
                            displayValue={(person) => `${person.name || ''} ${person.last_name || ''}`}
                          />
                          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </Combobox.Button>

                          {filteredMembers.length > 0 && (
                            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {filteredMembers.map((person) => (
                                <Combobox.Option
                                  key={person.id}
                                  value={person}
                                  className={({ active }) =>
                                    classNames(
                                      'relative cursor-default select-none py-2 pl-3 pr-9',
                                      active ? 'bg-green-600 text-white' : 'text-gray-900'
                                    )
                                  }
                                >
                                  {({ active, selected }) => (
                                    <>
                                      <span className={classNames('block truncate', selected && 'font-semibold')}>{person.name} {person.last_name}</span>

                                      {selected && (
                                        <span
                                          className={classNames(
                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                            active ? 'text-white' : 'text-green-600'
                                          )}
                                        >
                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      )}
                                    </>
                                  )}
                                </Combobox.Option>
                              ))}
                            </Combobox.Options>
                          )}
                        </div>
                      </Combobox>
                    </div>

                    <div className="relative flex items-ce pt-8 ">
                      <div className="flex h-6 items-center">
                        <input checked={memberInfo.guarantor_contract} onChange={(e) => setMemberInfo({...memberInfo, guarantor_contract: !memberInfo.guarantor_contract})}
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label htmlFor="offers" className="font-medium text-gray-900">
                          Contrato Socio Avalador
                        </label>
                      </div>
                    </div>
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input checked={memberInfo.consumption_contract} onChange={(e) => setMemberInfo({...memberInfo, consumption_contract: !memberInfo.consumption_contract})}
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label htmlFor="offers" className="font-medium text-gray-900">
                          Contrato de Consumo
                        </label>
                      </div>
                    </div>
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input checked={memberInfo.scanned_dni} onChange={(e) => setMemberInfo({...memberInfo, scanned_dni: !memberInfo.scanned_dni})}
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label htmlFor="offers" className="font-medium text-gray-900">
                          DNI Escaneado
                        </label>
                      </div>
                    </div>
                  </div> 
                </div>
                <div className="flex justify-center items-center pt-8 mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                    onClick={() => handleCreateMember()}
                  >
                    Confirmar
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => close()}
                  >
                    Cancelar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}